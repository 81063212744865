import Logo from '../../assets/logo.png'; 
import Discord from '../../assets/discord.svg'; 
import './style.css';

const Nav = () => {
  return (
    <nav className="flex justify-between items-center px-4 sm:px-8 py-6 border-b border-solid border-b-border">
      <img src={Logo} alt="logo"/>
      <ul className='hidden md:flex gap-6'>
        <li><a href="#" className='active' data-goto='header'>Home</a></li>
        <li><a href="#" data-goto='#pricing'>Pricing</a></li>
        <li><a href="#" data-goto='#portfolio'>Portfolio</a></li>
        <li><a href="#" data-goto='header'>About Me</a></li>
        <li><a href="#" data-goto='#contact'>Contact Me</a></li>
      </ul>
      <div className="flex gap-[4px]">
        <a href="https://discord.gg/svdden" target="_blank" className='flex justify-center items-center bg-primary w-[32px] h-[32px] rounded-lg'><img src={Discord} alt="discord" /></a>
      </div>
    </nav>
  );
};

export default Nav;