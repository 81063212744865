import Play from '../../assets/play.svg';
import Heart from '../../assets/heart.svg';
import Communication from '../../assets/communication.svg';
import Quality from '../../assets/quality.svg';
import Pig from '../../assets/pig.svg';

const WhatIOffer = () => {
  return (
    <section className="px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-44 max-w-screen-2xl mx-auto">
      <main className="grid lg:grid-cols-2 bg-background-tertiary rounded-[2.25rem]">
        <article className="flex flex-col justify-center p-8 sm:p-8 md:p-12 lg:p-16 xl:p-24 lg:border-r border-solid border-r-border">
          <h1 className="font-poppins font-bold text-5xl text-text-primary leading-normal">What I Offer</h1>
          <h2 className="text-2xl text-text-secondary font-semibold">Check out my previous work browse through the projects.</h2>
          <a href='https://www.youtube.com/channel/UCiNtCtF7zZDgcOR1oImC_Kw' target="_blank" rel="noopener noreferrer" className="flex items-center gap-8 mt-24">
            <button className="text-center w-[4.5rem] h-[4.5rem] bg-primary rounded-full"><img src={Play} alt="play" className='inline-block self-center' /></button>
            <p className='text-xl text-text-primary font-bold'>Latest Release</p>
          </a>
        </article>
        <ul className="p-8 sm:p-8 md:p-12 lg:p-16 xl:p-24">
          <li className='flex items-center justify-between lg:justify-end gap-8 mb-8'>
            <div className='lg:text-right flex-1'>
              <h5 className='text-xl text-text-primary font-extrabold leading-normal'>Expertise and Passion</h5>
              <p className='text-text-tertiary text-base font-bold'>Advanced FiveM development and customization.</p>
            </div>
            <div className="flex justify-center items-center w-20 h-20 rounded-2xl bg-green"><img src={Heart} alt="heart"/></div>
          </li>
          <li className='flex items-center justify-between lg:justify-end gap-8 mb-8'>
            <div className='lg:text-right flex-1'>
              <h5 className='text-xl text-text-primary font-extrabold leading-normal'>Effective Communication</h5>
              <p className='text-text-tertiary text-base font-bold'>Clear, and collaborative interactions with clients.</p>
            </div>
            <div className="flex justify-center items-center w-20 h-20 rounded-2xl bg-blue"><img src={Communication} alt="heart"/></div>
          </li>
          <li className='flex items-center justify-between lg:justify-end gap-8 mb-8'>
            <div className='lg:text-right flex-1'>
              <h5 className='text-xl text-text-primary font-extrabold leading-normal'>Swift, Quality Services</h5>
              <p className='text-text-tertiary text-base font-bold'>Fast, reliable, and high-standard FiveM solutions.</p>
            </div>
            <div className="flex justify-center items-center w-20 h-20 rounded-2xl bg-red"><img src={Quality} alt="heart"/></div>
          </li>
          <li className='flex items-center justify-between lg:justify-end gap-8'>
            <div className='lg:text-right flex-1'>
              <h5 className='text-xl text-text-primary font-extrabold leading-normal'>Accessible Pricing</h5>
              <p className='text-text-tertiary text-base font-bold'>Affordable rates for top-tier FiveM services.</p>
            </div>
            <div className="flex justify-center items-center w-20 h-20 rounded-2xl bg-yellow"><img src={Pig} alt="heart"/></div>
          </li>
        </ul>
      </main>
    </section>
  );
};

export default WhatIOffer;