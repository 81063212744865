import { useCallback, useEffect, useState } from "react";
import Moon from './assets/moon.svg';
import Sun from './assets/sun.svg';
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Portfolio from "./components/Portfolio";
import Pricing from "./components/Pricing";
import WhatIOffer from "./components/WhatIOffer";

function App() {

  const [theme, setTheme] = useState<string | null>(localStorage.getItem('theme'));

  const toggleTheme = useCallback(() => {
    const newTheme = localStorage.getItem('theme') === 'light' ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  }, []);

  useEffect(() => {
    let currentTheme = localStorage.getItem('theme');
    if (currentTheme == null) {
      const userTheme = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light';
      localStorage.setItem('theme', userTheme);
      currentTheme = userTheme;
    }

    document.documentElement.setAttribute('data-theme', currentTheme);
    setTheme(currentTheme);
  }, []);

  useEffect(() => {
    document.querySelectorAll('[data-goto]').forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        const element = document.querySelector(el.getAttribute('data-goto') ?? 'header');
        if (element)
          window.scrollTo({ top: element.getBoundingClientRect().top + window.scrollY, behavior: 'smooth' });
      })
    });
  }, []);

  return (<>
    <Header />
    <Portfolio />
    <WhatIOffer />
    <Pricing />
    <Contact />
    <Footer />

    <button className="fixed bottom-4 right-4 flex justify-center items-center w-16 h-16 z-50 rounded-full bg-primary" onClick={toggleTheme}>
      {
        theme === 'light' ? (
          <img src={Sun} alt="moon" />
        ) : (
          <img src={Moon} alt="moon" />
        )
      }
    </button>
  </>);
}

export default App;
