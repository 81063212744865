import { useMemo, useRef } from 'react';
import CaretLeft from '../../assets/caret_left.svg';
import CaretRight from '../../assets/caret_right.svg';
import image1 from '../../assets/portfolio_1.png';
import image2 from '../../assets/portfolio_2.png';
import image3 from '../../assets/portfolio_3.png';
import image4 from '../../assets/portfolio_4.png';
import image5 from '../../assets/portfolio_5.png';
import image6 from '../../assets/portfolio_6.png';
import image7 from '../../assets/portfolio_7.png';
import image8 from '../../assets/portfolio_8.png';
import image9 from '../../assets/portfolio_9.png';
import image10 from '../../assets/portfolio_10.png';

import Slider, { Settings } from 'react-slick';
import './style.css';

const Portfolio = () => {
  const slider = useRef<Slider>(null);
  const slideProps = useMemo<Settings>(() => ({
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }), []);

  return (
    <section id='portfolio' className="grid grid-cols-1 lg:grid-cols-2 items-center px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-16">
      <article>
        <h2 className="text-5xl font-poppins font-bold text-text-primary leading-normal">Portfolio</h2>
        <h3 className="text-2xl text-text-secondary font-semibold">Check out my previous work browse through the projects.</h3>
      </article>
      <div className='relative'>
        <button
          className="absolute top-1/2 -translate-y-1/2 -translate-x-1/3 flex justify-center items-center bg-primary rounded-full w-12 h-12 z-10"
          onClick={() => slider.current?.slickPrev()}
        >
          <img src={CaretLeft} alt="caretleft" />
        </button>
        <Slider {...slideProps} ref={slider}>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image1}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image2}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image3}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image4}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image5}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image6}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image7}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image8}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image9}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
          <div>
            <div className='rounded-3xl h-[340px] m-2' style={{ background: `url(${image10}) center/cover no-repeat`, backgroundSize: '100% 100%' }}></div>
          </div>
        </Slider>
        <button
          className="absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/3 flex justify-center items-center bg-primary rounded-full w-12 h-12"
          onClick={() => slider.current?.slickNext()}
        >
          <img src={CaretRight} alt="caretright" />
        </button>
      </div>
    </section>
  );
};

export default Portfolio;