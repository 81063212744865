import Logo from '../../assets/logo_68.png';

const Footer = () => {
  return (
    // <footer className="grid lg:grid-cols-2 justify-between items-center gap-x-24 gap-y-8 px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-16 border-t border-solid border-t-[rgba(40,40,40,0.24)] max-w-screen-2xl mx-auto">
    <footer className="border-t border-solid border-t-[rgba(40,40,40,0.24)]">
      <main className='flex flex-wrap justify-between items-center gap-x-24 gap-y-8 px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-16 max-w-screen-2xl mx-auto'>
        <article className='flex justify-center lg:justify-start flex-wrap gap-x-24 gap-y-8'>
          <img src={Logo} alt="logo" />
          <div className='flex flex-wrap justify-center lg:flex-1 gap-x-2 lg:gap-x-24 gap-y-4 text-center lg:text-left'>
            <div>
              <h5 className='font-black text-base text-text-primary leading-[2.5rem]'>Contact</h5>
              <a href="#" className='font-black text-base text-text-secondary' data-goto='#contact'>Contact Me</a>
            </div>
            <div>
              <h5 className='font-black text-base text-text-primary leading-[2.5rem]'>Pricing</h5>
              <a href="#" className='font-black text-base text-text-secondary' data-goto='#pricing'>View Pricing</a>
            </div>
            <div>
              <h5 className='font-black text-base text-text-primary leading-[2.5rem]'>Portfolio</h5>
              <a href="#" className='font-black text-base text-text-secondary' data-goto='#portfolio'>Check Portfolio</a>
            </div>
          </div>
        </article>
      </main>
    </footer>
  );
};

export default Footer;