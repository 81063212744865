const Pricing = () => {
  return (
    <section id='pricing' className="px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-24 max-w-screen-2xl mx-auto">
      <h1 className='text-center text-5xl text-text-primary font-poppins font-bold leading-normal'>Pricing</h1>
      <h2 className='text-center text-2xl text-text-secondary font-semibold mb-32'>Check out my previous work browse<br />through the projects.</h2>

      <div className="overflow-x-auto rounded-xl border border-solid border-border">
        <table className='border-separate border-spacing-0 w-full'>
          <thead>
            <tr>
              <th className='px-24 py-8 text-center text-2xl font-poppins font-bold border-b-[3px] border-solid border-b-[#1E1E1E] bg-white'>Starter</th>
              <th className='text-2xl text-center text-text-quaternary px-24 py-8 font-poppins font-bold border-b border-l border-solid border-b-border border-l-border bg-background-secondary'>Premium</th>
              <th className='text-2xl text-center text-text-quaternary px-24 py-8 font-poppins font-bold border-b border-l border-solid border-b-border border-l-border bg-background-secondary'>Elite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='p-6 md:p-8 lg:p-12 py-12 text-center'>
                <h4 className='text-text-primary font-black text-5xl mb-8'>Starter</h4>
                <h5 className='flex justify-center items-center gap-3 text-2xl text-text-secondary font-bold mb-14'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="29" viewBox="0 0 16 29" fill="none">
                    <path d="M8.61297 12.7278C5.04519 11.7772 3.89784 10.7944 3.89784 9.26389C3.89784 7.50778 5.48526 6.28333 8.14145 6.28333C10.9391 6.28333 11.9764 7.65278 12.0707 9.66667H15.5442C15.4342 6.89556 13.7839 4.35 10.499 3.52833V0H5.78389V3.48C2.73477 4.15667 0.282907 6.18667 0.282907 9.29611C0.282907 13.0178 3.28487 14.8706 7.66994 15.95C11.5992 16.9167 12.3851 18.3344 12.3851 19.8328C12.3851 20.9444 11.6149 22.7167 8.14145 22.7167C4.90373 22.7167 3.63065 21.2344 3.45776 19.3333H0C0.188605 22.8617 2.76621 24.8433 5.78389 25.5039V29H10.499V25.5361C13.5639 24.94 16 23.1194 16 19.8167C16 15.2411 12.1807 13.6783 8.61297 12.7278Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                  <span>299.99</span>
                  <span>Billed Monthly</span>
                </h5>

                <div className="flex justify-between items-center mb-32 cursor-pointer text-text-tertiary">
                  <p className="text-2xl font-bold">Features</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M0 0L8 8L16 0H0Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                </div>

                <a href="https://discord.gg/svdden" target="_blank" className="block bg-primary rounded-2xl text-white text-xl font-black px-16 py-6">Schedule Booking</a>
              </td>
              <td className='p-6 md:p-8 lg:p-12 py-12 text-center border-l border-solid border-l-border'>
                <h4 className='text-text-primary font-black text-5xl mb-8'>Premium</h4>
                <h5 className='flex justify-center items-center gap-3 text-2xl text-text-secondary font-bold mb-14'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="29" viewBox="0 0 16 29" fill="none">
                    <path d="M8.61297 12.7278C5.04519 11.7772 3.89784 10.7944 3.89784 9.26389C3.89784 7.50778 5.48526 6.28333 8.14145 6.28333C10.9391 6.28333 11.9764 7.65278 12.0707 9.66667H15.5442C15.4342 6.89556 13.7839 4.35 10.499 3.52833V0H5.78389V3.48C2.73477 4.15667 0.282907 6.18667 0.282907 9.29611C0.282907 13.0178 3.28487 14.8706 7.66994 15.95C11.5992 16.9167 12.3851 18.3344 12.3851 19.8328C12.3851 20.9444 11.6149 22.7167 8.14145 22.7167C4.90373 22.7167 3.63065 21.2344 3.45776 19.3333H0C0.188605 22.8617 2.76621 24.8433 5.78389 25.5039V29H10.499V25.5361C13.5639 24.94 16 23.1194 16 19.8167C16 15.2411 12.1807 13.6783 8.61297 12.7278Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                  <span>449.99</span>
                  <span>Billed Monthly</span>
                </h5>

                <div className="flex justify-between items-center mb-32 cursor-pointer text-text-tertiary">
                  <p className="text-2xl font-bold">Features</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M0 0L8 8L16 0H0Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                </div>

                <a href="https://discord.gg/svdden" target="_blank" className="block bg-[rgba(87,87,87,0.48)] rounded-2xl text-white text-xl font-black px-16 py-6">Schedule Booking</a>
              </td>
              <td className='p-6 md:p-8 lg:p-12 py-12 text-center border-l border-solid border-l-border'>
                <h4 className='text-text-primary font-black text-5xl mb-8'>Elite</h4>
                <h5 className='flex justify-center items-center gap-3 text-2xl text-text-secondary font-bold mb-14'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="29" viewBox="0 0 16 29" fill="none">
                    <path d="M8.61297 12.7278C5.04519 11.7772 3.89784 10.7944 3.89784 9.26389C3.89784 7.50778 5.48526 6.28333 8.14145 6.28333C10.9391 6.28333 11.9764 7.65278 12.0707 9.66667H15.5442C15.4342 6.89556 13.7839 4.35 10.499 3.52833V0H5.78389V3.48C2.73477 4.15667 0.282907 6.18667 0.282907 9.29611C0.282907 13.0178 3.28487 14.8706 7.66994 15.95C11.5992 16.9167 12.3851 18.3344 12.3851 19.8328C12.3851 20.9444 11.6149 22.7167 8.14145 22.7167C4.90373 22.7167 3.63065 21.2344 3.45776 19.3333H0C0.188605 22.8617 2.76621 24.8433 5.78389 25.5039V29H10.499V25.5361C13.5639 24.94 16 23.1194 16 19.8167C16 15.2411 12.1807 13.6783 8.61297 12.7278Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                  <span>849.99</span>
                  <span>Billed Monthly</span>
                </h5>

                <div className="flex justify-between items-center mb-32 cursor-pointer text-text-tertiary">
                  <p className="text-2xl font-bold">Features</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M0 0L8 8L16 0H0Z" fill="currentColor" fillOpacity="0.75" />
                  </svg>
                </div>

                <a href="https://discord.gg/svdden" target="_blank" className="block bg-[rgba(87,87,87,0.48)] rounded-2xl text-white text-xl font-black px-16 py-6">Schedule Booking</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Pricing;