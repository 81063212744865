import Nav from "../Nav";
import './style.css';
import HeaderImg from '../../assets/header_img.png';

const Header = () => {
  return (
    <header>
      <Nav />
      <main className="flex flex-wrap justify-between gap-8 px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-8">
        <article className="flex flex-1 flex-col">
          <div className="flex flex-col justify-center flex-1">
            <div className="mb-20">
              <h1 className="font-poppins font-bold text-7xl sm:text-8xl leading-normal text-text-primary">Svdden</h1>
              <h2 className="font-semibold text-2xl sm:text-[2rem] text-text-secondary">Full stack developer specializing in FiveM and web.</h2>
            </div>
            <div className="flex items-center gap-x-4 sm:gap-x-10 gap-y-2 flex-wrap mb-24">
              <a href="#" className="button" data-goto='#pricing'>Pricing</a>
              {/* <a href="#" className="text-xl text-text-tertiary font-black">Learn more</a> */}
            </div>
          </div>
          <button className="flex items-center gap-2" data-goto='#portfolio'>
            <div className="flex justify-center items-center w-8 h-8 bg-background-secondary rounded-full text-text-tertiary">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                <path d="M5 1V11M5 11L9 7.25M5 11L1 7.25" stroke="currentColor" strokeOpacity="0.48" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <span className="text-sm text-text-tertiary font-bold">Scroll Down</span>
          </button>
        </article>
        <img src={HeaderImg} alt="img" draggable={false} className="select-none hidden lg:block max-w-[95vw] max-h-full" />
      </main>
    </header>
  );
};

export default Header;