const Contact = () => {
  return (
    <section id='contact' className="flex flex-wrap justify-between items-center gap-8 px-4 sm:px-12 md:px-24 lg:px-32 xl:px-44 py-24 max-w-screen-2xl mx-auto">
      <article className="flex-1">
        <h1 className='text-5xl text-text-primary font-poppins font-bold leading-normal'>Contact Me</h1>
        <h2 className='text-2xl text-text-secondary font-semibold'>Get in touch for bespoke FiveM development solutions.</h2>
      </article>
      <article className="flex-1 bg-background-tertiary rounded-3xl p-8 lg:p-16 py-16">
        <form action="https://discord.gg/svdden" target="_blank" method="post">
          <button type="submit" className="block w-full bg-primary rounded-2xl text-white text-[0.9vw] font-bold px-16 py-6">Discord</button>
        </form>
      </article>
    </section>
  );
};

export default Contact;